import {
  take,
  put,
  all,
  call,
  race,
  fork,
  cancel,
  takeLatest
} from "redux-saga/effects";
import { listenCallEvents } from "./event-handler";
import { watchCallActions } from "./connection-handler";
import { Types } from "actions/phone";

import * as Sentry from "@sentry/react";

function* watchAccept(twilioCall) {
  while (true) {
    yield take(Types.ACCEPT_INCOMING);
    twilioCall.accept();
  }
}

function* watchReject(twilioCall) {
  while (true) {
    yield take(Types.REJECT_INCOMING);
    twilioCall.reject();
  }
}

export function* watchIncomingCallActions(twilioCall) {
  yield all([call(watchAccept, twilioCall), call(watchReject, twilioCall)]);
}

function* handleIncomingCall(device, { payload: { twilioCall } }) {
  try {
    // const autoAnswerIncoming = twilioCall?.customParameters?.get("auto_answer");
    const from = twilioCall?.customParameters?.get("From")?.replace("+1", "");

    const watchCallActionsTask = yield fork(
      watchCallActions,
      twilioCall,
      device
    );
    const callTask = yield fork(listenCallEvents, twilioCall, device);

    yield put({ type: Types.SET_CALL_DIRECTION, payload: "inbound" });
    if (from)
      yield put({ type: Types.SET_CONNECTED_INCOMING_NUMBER, payload: from });
    /*
    if (autoAnswerIncoming) {
      twilioCall.accept();
    }
    */
    const { accepted } = yield race({
      disconnect: take(Types.TWILIO_EVENT_DISCONNECT),
      accepted: take(Types.TWILIO_EVENT_ACCEPT_INCOMING),
      rejected: take(Types.TWILIO_EVENT_REJECT_INCOMING)
    });

    if (accepted)
      yield put({ type: Types.TWILIO_EVENT_CONNECT, payload: { twilioCall } });
    yield cancel([callTask, watchCallActionsTask]);
  } catch (error) {
    console.error(error);
    Sentry.withScope(scope => {
      scope.setTag("location", window.location.href);
      scope.setTag("incomingCall", true);
      Sentry.captureException(error);
    });
  }
}

export default function* watchIncomingCall(device) {
  yield takeLatest(Types.TWILIO_EVENT_INCOMING, handleIncomingCall, device);
}
